import React, { useState } from "react";
import { Form, Row, Col, Input, Button, Typography, Select } from "antd";
import { useEffect } from "react";

const { Option } = Select;
const { Title } = Typography;
const ListForm = ({
  formItems = [],
  onSubmit,
  User,
  addressOption,
  addressData,
  selectedService,
  allcategory,
  collectedfee,
  selectedbranch,
  medicaldiscount,
  totalTourism
}) => {
  const [form] = Form.useForm();
  const [showNewAddressForm, setShowNewAddressForm] = useState(false);
  const [addressType, setAddressType] = useState("");
  const [address, setAddress] = useState("");
  const handleSaveAddress = (address) => {
    setAddress(address);
    // onSubmit(formValues, address); // Call onSubmit and pass both form values and address
  };
  const [collectedFee, setCollectedFee] = useState(collectedfee);
  useEffect(() => {
    setCollectedFee(collectedfee);
  }, [collectedfee]);

  const [medicalDiscount, setMedicaldiscount] = useState(medicaldiscount);
  useEffect(() => {
    setMedicaldiscount(medicaldiscount);
  }, [medicaldiscount]);

  const [totalTourisM, setTotalTourism] = useState(totalTourism);
  useEffect(() => {
    setTotalTourism(totalTourism);
  }, [totalTourism]);
  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        console.log("🚀 ~ .then ~ values:", values);
        onSubmit({ ...values }, address);
      })
      .catch((errorInfo) => {});
  };

  useEffect(() => {
    if (addressOption) {
      // form.setFieldsValue({
      //   name: user?.name || "",
      //   email: user?.email || "",
      //   dob: user?.dob || "",
      //   gender: user?.gender || "",
      //   address: addressData || addressOption,
      // });

      form.setFieldValue("name", User?.name || "");
      form.setFieldValue("email", User?.email || "");
      form.setFieldValue("dob", User?.dob || "");
      form.setFieldValue("gender", User?.gender || "");
      form.setFieldValue("address", addressData || addressOption);
      form.setFieldValue("mobileNumber",User?.phone || "");
    } else if (User) {
      // form.setFieldsValue({
      //   name: user?.name || "",
      //   email: user?.email || "",
      //   address: addressData || "",
      // });
      form.setFieldValue("name", User?.name || "");
      form.setFieldValue("email", User?.email || "");
      form.setFieldValue("address", addressData || "");
      form.setFieldValue("mobileNumber",User?.phone || "");

    }
  }, [User, addressOption, addressData]);

  useEffect(() => {
    form.setFieldValue("category", "");
    form.setFieldValue("subcatgeory", null);
    form.setFieldValue("Doctor");
    form.setFieldValue("Booking_date");
    form.setFieldValue("PreferedTime");
    form.setFieldValue("progarm");
  }, [selectedService]);
  useEffect(() => {
    // form.setFieldValue("category", null);
    form.setFieldValue("subcatgeory", null);
    form.setFieldValue("progarm");
    form.setFieldValue("Doctor");
    form.setFieldValue("Booking_date");
    form.setFieldValue("PreferedTime");
    form.setFieldValue("progarm");
    

  }, [allcategory]);
  useEffect(() => {
    setCollectedFee(""); // Reset collectedFee when category changes
    setMedicaldiscount("")
    setTotalTourism("");
  }, [allcategory]);
  useEffect(() => {
    form.setFieldValue("service")
    form.setFieldValue("category", "");
    form.setFieldValue("subcatgeory", null);
    form.setFieldValue("Doctor");
    form.setFieldValue("Booking_date");
    form.setFieldValue("PreferedTime");
    form.setFieldValue("progarm");
  }, [selectedbranch]);

  useEffect(() => {
    if (addressType) {
      form.setFieldValue("addressType", addressType);
    }
  }, [addressType]);

  console.log("getFieldsValue", form.getFieldsValue());
  return (
    <Form className="booking-form" form={form} layout="vertical">
      <Col xs={24} md={16} lg={16}>
        {" "}
        <Row gutter={24}>
          {formItems.map(({ Component, ...formItem }) => {
             if (formItem.key === "progarm" && selectedService !== "Surgery Consultation" && selectedService !== "Medical Tourism") {
              return null;
            }
            if (formItem.key === "payment" && selectedService !== "Medical Tourism" ) {
              return null;
            }
            if (formItem.key === "paymenttype" && selectedService  !== "Surgery Consultation" && selectedService  !== "medical" ) {
              return null;
            }
            if (formItem.key === "Description" && selectedService !== "Medical Tourism"){
              return null;
            }
            if (formItem.key === "casefile_url" && selectedService !== "Medical Tourism" && selectedService !== "Surgery Consultation"){
              return null;
            }
            // if (formItem.key === "paymenttype" && selectedService  !== "medical"){
            //   return null;
            // }
            if (
              selectedService == "Emergency" &&
              formItem?.heading === "Address Details" ||
              formItem?.heading === "Patient Details" ||
              formItem?.heading === "Booking Details" ||
              formItem?.heading === "Branch Details"
            
            ) {
              return (
                <Col
                  className="gutter-row section-heading"
                  xs={24}
                  md={24}
                  lg={24}
                >
                  <Title
                    level={2}
                    className="d-flex"
                    style={{
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    {formItem?.heading}
                  </Title>
                </Col>
              );
            }

            if (formItem?.name === "button"&&
              selectedService == "Emergency") {
              return (
                <Col xs={24} md={12} lg={12} key={formItem?.name}>
                  <div className="addressFormBtn">{Component}</div>
                </Col>
              );
            }
            if (!formItem?.key) {
              return;
            }
            if(formItem?.key ==="Emergencytype" && selectedService!== "Emergency")
              {
                return null;
              }
              if(formItem?.key ==="Notes" && selectedService!== "Emergency")
                {
                  return null;
                }
              
            if(formItem?.key === "category" && selectedService == "Emergency")
              {
              return null;
              }
              if(formItem?.key ==="program" && selectedService == "Emergency"){
                return null;
              }
              if(formItem?.key ==="subcatgeory" && selectedService == "Emergency"){
                return null;
              }
              if(formItem?.key ==="Doctor" && selectedService == "Emergency"){
                return null;
              }
              if(formItem?.key ==="Booking_date" && selectedService == "Emergency"){
                return null;
              }
              if(formItem?.key ==="PreferedTime" && selectedService == "Emergency"){
                return null;
              }
             // new adress form start
            if (
              formItem?.key === "address" &&
              (selectedService !== "Emergency"
               )
            ) {
              return null;
            }
            if (
              formItem?.key === "newAddress" && (
                selectedService !== "Emergency"
              )
            ){
              return null;
            }
            if (
              formItem?.key === "houseNumber" && (
                selectedService !== "Emergency"
              )
            ){
              return null;
            }
            if (
              formItem?.key === "landmark" && (
                selectedService !== "Emergency"
              )
            ){
              return null;
            }
            if (
              formItem?.key === "mobileNumber" && (
                selectedService !== "Emergency"
              )
            ){
              return null;
            }
            if (
              formItem?.key === "addressType" && (
                selectedService !== "Emergency"
              )
            ){
              return null;
            }
            // new adress form end
            
            if (formItem.key === "map") {
              return (
                <Col xs={24} md={24} lg={24} key={formItem.key}>
                  {Component || null}
                </Col>
              );
            }
            return (
              <Col xs={24} md={12} lg={12} key={formItem.key}>
                <Form.Item
                  name={formItem.key}
                  {...formItem}
                  label={formItem.label}
                >
                  {Component || (
                    <Input placeholder={formItem.label} size="large" />
                  )}
                </Form.Item>
              </Col>
            );
          })}
        </Row>
        {selectedService !== "Emergency" && (
        <>
          <div className="adddress_detailslist">
            <label htmlFor="fare-calculation">Consultation Fee: {collectedFee}</label>
          </div>
          <div className="adddress_detailslist">
            <label htmlFor="fare-calculation">Total Discount: {medicalDiscount ? medicalDiscount : "0"}</label>
          </div>
          <div className="adddress_detailslist">
            <label htmlFor="fare-calculation">Total Payable: {selectedService !== "Medical Tourism" ? collectedFee : totalTourisM}</label>
          </div>
        </>
      )}
        <Row justify="end">
          <Col>
            <Button type="primary" onClick={handleSubmit}>
              Submit
            </Button>
          </Col>
        </Row>
      </Col>
    </Form>
  );
};

export default ListForm;
