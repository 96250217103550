/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Table,
  Typography,
  Select,
  DatePicker,
  Button,
  message,
  Row,
  Col,
  Spin,
  Modal,
} from "antd";
import moment from "moment";
import { unset, get, set } from "lodash";
import { Link } from "react-router-dom";
import { SearchOutlined, SyncOutlined } from "@ant-design/icons";
import ConfimBookingButton from "./components/ConfirmBookingButton";
import ConfirmCaseSheetButton from "./components/ConfirmCaseSheetButton";
import ConfirmCaseFileButton from "./components/ConfirmCaseFileButton";
import DetailsDrawer from "./components/ViewDetailsDrawer";
import AdvancedSearchForm from "../../../common/SearchForm";
import PaymentDetails from "./components/PaymentDetails";
import jsPDF from "jspdf";
import "jspdf-autotable"; // Import for table support
const {
  Title,
  Text,
  //  Link
} = Typography;
const { Option } = Select;
const { RangePicker } = DatePicker;

function UserManagement(props) {
  const bookingMessage = useSelector(
    (state) => state.homeHealthcareReducer.bookingMessage
  );

  const userInfo = JSON.parse(localStorage.getItem("userObj"));
  console.log("wsss", userInfo);

  const [showDetailsDrawer, setShowDetailsDrawer] = useState(false);
  const [searchOption, setSearchOption] = useState(false);
  const [moreInfoModal, setMoreInfoModal] = useState(false);
  const [columnValue, setColumnValue] = useState("");
  const [currentColumn, setCurrentColumn] = useState({});
  const [paymentDetails, setPaymentDetails] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [serviceListOption, setServiceListOption] = useState(false);
  const [staffListOption, setStaffListOption] = useState(false);
  const [selectedService, setSelectedService] = useState(false);
  const [programListOption, setProgramListOption] = useState(false);
  const [searchValues, setSearchValues] = useState();

  const confirmHomeHealthcareBooking = async (params) => {
    await props.confirmHomeHealthcareBooking(params);
    await props.getAllHomeHealthcareBookings(undefined, 20, currentPage);
  };

  const showPaymentDetails = (data) => {
    console.log("🚀 ~ showPaymentDetails ~ data:", data);
    setPaymentDetails(true);
    setCurrentColumn(data);
  };

  // const downloadInvoice = async (bookingData) => {
  //   console.log("🚀 ~ downloadInvoice ~ bookingData:", bookingData);

  //   try {
  //     const invoiceNumber = `INV-${bookingData.id}-${Date.now()}`; // Generate an invoice number

  //     const doc = new jsPDF();

  //     // Set the title
  //     doc.setFontSize(16);
  //     doc.text("Invoice", 105, 20, { align: "center" });

  //     // Add Invoice Number
  //     doc.setFontSize(12);
  //     doc.text(`Invoice Number: ${invoiceNumber}`, 10, 30);

  //     // Add Booking Details
  //     doc.text(`Booking ID: ${bookingData.bid}`, 10, 40);
  //     doc.text(`Booking Date: ${bookingData.booking_date}`, 10, 50);
  //     doc.text(`Patient Name: ${bookingData.patient_name}`, 10, 60);
  //     doc.text(`Patient Phone: ${bookingData.patient_phone}`, 10, 70);
  //     doc.text(`Patient Age / Gender: ${bookingData.patient_age} / ${bookingData.patient_gender}`, 10, 80);
  //     doc.text(`Patient Address: ${bookingData.patient_address}`, 10, 90);
  //     doc.text(`Program Name: ${bookingData.program_name}`, 10, 100);
  //     doc.text(`Program Duration: ${bookingData.program_duration}`, 10, 110);
  //     doc.text(`Total Sessions: ${bookingData.total_sessions}`, 10, 120);
  //     doc.text(`Schedule: ${bookingData.schedule}`, 10, 130);
  //     doc.text(`Preferred Time: ${bookingData.preferred_time}`, 10, 140);

  //     // Payment Details
  //     const paymentSummary = bookingData.payment_details.summary;
  //     doc.text("Payment Summary", 10, 160);
  //     doc.text(`Total Payable: ${paymentSummary.currency} ${paymentSummary.total_payable}`, 10, 170);
  //     doc.text(`Advance Payment: ${paymentSummary.currency} ${paymentSummary.total_advance_payment}`, 10, 180);
  //     doc.text(`Pending Amount: ${paymentSummary.currency} ${paymentSummary.pending_amount}`, 10, 190);

  //     // If payment transactions exist
  //     if (bookingData.payment_details.payment.length > 0) {
  //       const paymentTable = bookingData.payment_details.payment.map((payment, index) => [
  //         index + 1,
  //         payment.date,
  //         payment.mode,
  //         payment.amount,
  //       ]);

  //       doc.autoTable({
  //         head: [["#", "Date", "Payment Mode", "Amount"]],
  //         body: paymentTable,
  //         startY: 200,
  //       });
  //     }

  //     // Save the PDF
  //     doc.save(`Invoice_${bookingData.bid}.pdf`);
  //   } catch (error) {
  //     console.error("Error generating invoice PDF:", error);
  //     message.error("Failed to generate the invoice.");
  //   }
  // };

  const downloadInvoice = (bookingData) => {
    console.log("🚀 ~ downloadInvoice ~ bookingData:", bookingData);
    const invoiceNumber = `INV-${bookingData.id}`;
    const doc = new jsPDF();

    // Add Logo (Left Aligned)
    const img = "/B Logo copy.png"; // Replace with the logo's path
    doc.addImage(img, "PNG", 10, 10, 30, 30);

    // Title (Center)
    doc.setFontSize(18);
    doc.text("INVOICE", 105, 20, { align: "center" });

    // Company Details (Left)
    doc.setFontSize(10);
    doc.setFont("Helvetica", "bold");
    doc.text(`${userInfo.profile.company_name || "Company Name"}`, 10, 50); // Company Name

    doc.setFont("Helvetica", "normal");
    doc.text(`${userInfo.name}`, 10, 56);
    doc.text(`${userInfo.phone_code}${userInfo.phone}`, 10, 62);
    doc.text(`${userInfo.profile.email}`, 10, 68);
    doc.text(`${userInfo.profile.address || "Chennai, 60037"}`, 10, 74);

    // Invoice Details (Right Side - Proper Alignment)
    const labelX1 = 140; // Fixed position for labels
    const colonX = 175; // Position for colons
    const valueX = 200; // Position for values

    const currentDate = new Date();
    const formattedDate = `${currentDate.toLocaleDateString()}`;
    const formattedTime = `${currentDate.toLocaleTimeString()}`;

    doc.setFont("Helvetica", "normal");

    // Invoice Number
    doc.text(`Invoice Number`, labelX1, 50);
    doc.text(`:`, colonX, 50);
    doc.setFont("Helvetica", "bold");
    doc.text(`${invoiceNumber}`, valueX, 50, { align: "right" });

    // Invoice Date
    doc.setFont("Helvetica", "normal");
    doc.text(`Invoice Date`, labelX1, 56);
    doc.text(`:`, colonX, 56);
    doc.setFont("Helvetica", "bold");
    doc.text(`${formattedDate}`, valueX, 56, { align: "right" });

    // Invoice Time
    doc.setFont("Helvetica", "normal");
    doc.text(`Invoice Time`, labelX1, 62);
    doc.text(`:`, colonX, 62);
    doc.setFont("Helvetica", "bold");
    doc.text(`${formattedTime}`, valueX, 62, { align: "right" });

    // Bill To Section (Now Includes Address Without "India")
    doc.setFont("Helvetica", "bold");
    doc.text("Bill To", 10, 85);
    doc.text(`${bookingData.patient_name}`, 10, 91);

    doc.setFont("Helvetica", "normal");

    // Dynamically Format Patient Address
    let address = bookingData.patient_address || "Chennai";

    // Remove "India" if it appears at the end
    if (address.trim().endsWith("India")) {
      address = address.replace(/,?\s*India$/, ""); // Remove ", India" or " India"
    }

    // Split and print each line
    const addressLines = address.split(",").map((line) => line.trim());

    let addressY = 97;
    addressLines.forEach((line) => {
      doc.text(line, 10, addressY);
      addressY += 6; // Move to the next line
    });

    /// Booking Details (Left Aligned)
    doc.setFont("Helvetica", "bold");
    doc.text("Booking Details", 10, addressY + 5); // Heading

    doc.setFont("Helvetica", "normal");

    // Small spacing adjustment
    let yPosition = addressY + 13; // Slightly closer to the heading

    const bookingDetails = [
      { label: "Booking ID", value: bookingData?.bid },
      {
        label: "Program",
        value: `${bookingData?.program_name} (${bookingData?.module})` || "N/A",
      },
      { label: "Schedule", value: bookingData?.schedule || "N/A" },
      { label: "Sessions", value: bookingData?.total_sessions },
      { label: "Duration", value: bookingData?.program_duration || "N/A" },
    ];

    bookingDetails.forEach((detail) => {
      doc.text(`${detail.label}: ${detail.value}`, 10, yPosition);
      yPosition += 6; // Maintain consistent spacing
    });

    // Table (Properly Aligned)
    const tableData = [
      [
        "1",
        `${bookingData?.module}\n(${bookingData?.program_name})`,
        bookingData?.total_sessions,
        bookingData.payment_details.summary.total_payable,
      ],
    ];

    doc.autoTable({
      startY: yPosition + 10,
      head: [["#", "Item Name", "Sessions", "Price"]],
      body: tableData,
      theme: "grid",
      headStyles: { fillColor: [41, 128, 185], textColor: [255, 255, 255] },
      bodyStyles: { textColor: [0, 0, 0] },
      columnStyles: {
        0: { cellWidth: 20 }, // Number column
        1: { cellWidth: 90 }, // Item Name column
        2: { cellWidth: 40 }, // Sessions column
        3: { cellWidth: 40, halign: "right" }, // Price column (Right Aligned)
      },
      styles: {
        cellPadding: 5,
        overflow: "linebreak",
      },
      margin: { left: 10, right: 10 },
    });

    // Right Side Totals (Perfect Right Alignment)
    const labelX = 160; // Move to the right side
    const amountX = 200;
    const finalY = doc.lastAutoTable.finalY + 10;

    doc.setFont("Helvetica", "normal");
    doc.text(`Item Total:`, labelX, finalY, { align: "right" });
    doc.text(
      `${bookingData.payment_details.summary.total_payable.toFixed(2)}`,
      amountX,
      finalY,
      { align: "right" }
    );

    doc.text(`Discount:`, labelX, finalY + 10, { align: "right" });
    doc.text(`0`, amountX, finalY + 10, { align: "right" });

    doc.text(`Additional Discount:`, labelX, finalY + 20, { align: "right" });
    doc.text(`0`, amountX, finalY + 20, { align: "right" });

    // Total (Bold, Right Aligned)
    doc.setFont("Helvetica", "bold");
    doc.text(`Total Payable:`, labelX, finalY + 30, { align: "right" });
    doc.text(
      `${bookingData.payment_details.summary.total_payable.toFixed(2)}`,
      amountX,
      finalY + 30,
      { align: "right" }
    );

    // Balance Due (Highlighted, Right Aligned)
    doc.setFillColor(240, 240, 240);
    doc.text(`Paid Till Now:`, labelX, finalY + 40, { align: "right" });
    doc.text(
      `${bookingData.payment_details.summary.total_advance_payment.toFixed(2)}`,
      amountX,
      finalY + 40,
      { align: "right" }
    );

    // Total in Words (Right-Aligned Under Balance Due)
    doc.setFontSize(10);
    doc.setFont("Helvetica", "bold");
    doc.setTextColor(0, 0, 0);
    doc.text(`Pending Amount:`, labelX, finalY + 50, { align: "right" });

    doc.setFont("Helvetica", "bold");
    doc.text(
      `${bookingData.payment_details.summary.pending_amount.toFixed(2)}`,
      amountX,
      finalY + 50,
      { align: "right" }
    );

    // Save PDF
    doc.save(`${invoiceNumber}.pdf`);
    window.location.reload();
  };

  useEffect(() => {
    props.getAllHomeHealthcareServicesList().then((response) => {
      setServiceListOption(response?.value?.data?.data);
    });
    props.getAllHomeHealthcareCaregiverStaff().then((response) => {
      setStaffListOption(response?.value?.data?.data);
      console.log("caregiver", response?.value?.data?.data);
    });
    props.getAllHomeHealthcareServices().then((response) => {
      console.log("response", response);

      const services_data = response?.value?.data?.data;

      // const newResult = services_data.filter(each => each.packages?.length).map(each => {
      // return each.packages?.map(eachPack => ({id: eachPack?.id,service_id:eachPack.service_id,name:eachPack.name}))});

      const packges_data = [];

      services_data.forEach((item) => {
        const packages = item.packages;
        packages.forEach((data) => {
          packges_data.push({
            package_id: data.id,
            package_name: data.name,
            service_id: item.home_service_id,
            service_name: item.name,
          });
        });
      });

      console.log("packges_data", packges_data);
      setProgramListOption(packges_data);
    });

    if (searchValues) {
      props.getAllHomeHealthcareBookings(`${searchValues}`, 20, currentPage);
    } else {
      props.getAllHomeHealthcareBookings(undefined, 20, currentPage);
    }
  }, [props.cancelBooking, currentPage]);

  const handleRefresh = () => {
    props.getAllHomeHealthcareBookings(undefined, 20, currentPage);
    if (bookingMessage && bookingMessage) {
      message.success(bookingMessage.message);
    }
  };

  const handleServiceSelect = (e) => {
    console.log("setSelectedService", e);
    setSelectedService(e);
  };

  const clearSelectedService = () => {
    setSelectedService();
  };

  const columns = [
    {
      title: "Service",
      key: "id",
      width: "23%",
      render: (_, columnData) => (
        <Row className="name-details-row">
          <Col>
            <div className="table-fc-name">{columnData.module}</div>
            <div className="table-fc-email">Booking ID: {columnData.bid}</div>
            <div className="table-fc-email">
              Date: {columnData.booking_date}
            </div>
            <div className="view-details">
              <Link onClick={() => setShowDetailsDrawer(columnData.id)}>
                VIEW DETAILS
              </Link>
            </div>{" "}
          </Col>
        </Row>
      ),
    },
    {
      title: "Patient Details",
      key: "patient_details",
      render: (_, columnData) => (
        <>
          <Row>
            <Col span={24}>{columnData.patient_name} </Col>
            <Col span={24} className="lightFont">
              {columnData.patient_age} / {columnData.patient_gender}
            </Col>
            <Col span={24} className="lightFont">
              {columnData.patient_phone}
            </Col>
          </Row>
          <div>
            {columnData.case_files_button ? (
              <ConfirmCaseFileButton
                columnData={columnData}
                confirmHomeHealthcareBooking={confirmHomeHealthcareBooking}
              />
            ) : null}
          </div>
          <div>
            {columnData.case_sheet_button ? (
              <ConfirmCaseSheetButton
                columnData={columnData}
                confirmHomeHealthcareBooking={confirmHomeHealthcareBooking}
              />
            ) : null}
          </div>
          <div className="view-details">
            <Link onClick={() => showPaymentDetails(columnData)}>
              PAYMENT INFO
            </Link>
          </div>
          <div className="view-details">
            <Link onClick={() => downloadInvoice(columnData)}>INVOICE</Link>
          </div>
        </>
      ),
    },
    {
      title: "Package Details",
      key: "service_details",
      render: (_, columnData) => (
        <>
          <Row>
            <Col span={24}>{columnData.program_name} </Col>
            <Col span={24} className="lightFont">
              {columnData.program_duration}
            </Col>
            <Col span={24} className="lightFont">
              {columnData.total_sessions} Sessions
            </Col>
          </Row>
          <Row>
            <Col span={24} className="lightFont">
              {columnData.schedule}{" "}
            </Col>
            <Col span={24} className="lightFont">
              ({columnData.preferred_time})
            </Col>
          </Row>
        </>
      ),
    },
    {
      title: "Status",
      key: "status_code",
      dataIndex: "status_code",
      render: (_, columnData) => (
        <Row>
          <Col span={24}>
            {columnData.status_code === 1 ? (
              <span className="booking-placed">{columnData.status}</span>
            ) : null}
            {columnData.status_code === 2 ? (
              <span className="booking-confirmed">{columnData.status}</span>
            ) : null}
            {columnData.status_code === 3 ? (
              <span className="booking-inprogress">{columnData.status}</span>
            ) : null}
            {columnData.status_code === 4 ? (
              <span className="booking-completed">{columnData.status}</span>
            ) : null}
            {columnData.status_code === 5 ? (
              <span className="booking-cancelled">{columnData.status}</span>
            ) : null}
            {columnData.status_code === 6 ? (
              <span className="booking-cancelled">{columnData.status}</span>
            ) : null}
            {columnData.status_code === 7 ? (
              <span className="booking-placed">{columnData.status}</span>
            ) : null}
          </Col>
          <Col span={24} className="lightFont">
            {columnData.booking_date}
          </Col>
        </Row>
      ),
    },
    {
      title: "Action",
      width: "25%",
      render: (_, columnData) => (
        <div>
          {columnData.status_code === 1 && (
            <ConfimBookingButton
              columnData={columnData}
              confirmHomeHealthcareBooking={confirmHomeHealthcareBooking}
              getAllHomeHealthcareHelpOption={
                props.getAllHomeHealthcareHelpOption
              }
              helpOption={props.helpOption}
              getAllHomeHealthcareBookings={props.getAllHomeHealthcareBookings}
              cancelHomeHealthcareBooking={props.cancelHomeHealthcareBooking}
            />
          )}
          {columnData.status_code !== 1 && columnData.status_code !== 5 && (
            <>
              <Link
                to={`/home/admin/booking/sessions?booking_id=${columnData.id}`}
              >
                VIEW SESSIONS
              </Link>
            </>
          )}
          {columnData.cancel_info_button === 1 && (
            <>
              <Link
                onClick={() => {
                  setMoreInfoModal(true);
                  setColumnValue(columnData);
                }}
              >
                MORE INFO
              </Link>
            </>
          )}
        </div>
      ),
    },
  ];
  const drawerConfig = [
    {
      displayName: "Booking Date",
      key: "booking_date",
    },
    {
      displayName: "Booking Status",
      key: "status",
    },
    {
      displayName: "Service Name",
      key: "module",
    },
    {
      displayName: "Patient Name",
      key: "patient_name",
    },
    {
      displayName: "Patient Age",
      key: "patient_age",
    },
    {
      displayName: "Patient Gender",
      key: "patient_gender",
    },
    {
      displayName: "Vendor Name",
      key: "patient_phone",
    },
    {
      displayName: "Patient Address",
      key: "patient_address",
    },
    {
      displayName: "Ailment description",
      key: "ailment_desc",
    },
    {
      displayName: "Total sessions",
      key: "total_sessions",
    },
    {
      displayName: "Preferred Time",
      key: "preferred_time",
    },
    {
      displayName: "Preferred Caregiver",
      key: "caregiver_gender",
    },
    {
      displayName: "Program name",
      key: "program_name",
    },
    {
      displayName: "Program Duration",
      key: "program_duration",
    },
    {
      displayName: "Total Payable",
      key: "total_payable",
    },
    {
      displayName: "Advance Payment",
      key: "advance_payment",
    },
    {
      displayName: "Pending Amount",
      key: "pending_amount",
    },
  ];
  const onSearch = (values) => {
    const newValues = JSON.parse(JSON.stringify(values));
    if (newValues["q[date_from_to]"]) {
      const [from, to] = get(newValues, "q[date_from_to]");
      unset(newValues, "q[date_from_to]");
      set(newValues, ["q[created_at_gteq]"], moment(from).format("DD/MM/YYYY"));
      set(newValues, ["q[created_at_lteq]"], moment(to).format("DD/MM/YYYY"));
    }
    const queryString = Object.keys(newValues)
      .map((key) => `${key}=${newValues[key]}`)
      .join("&");

    setSearchValues(queryString);
    props.getAllHomeHealthcareBookings(queryString, 20, 1);
    setCurrentPage(1);
  };

  const toggleSearch = () => {
    setSearchOption(!searchOption);
  };

  return (
    <Spin spinning={props.Loading === true}>
      <Modal
        footer={false}
        visible={moreInfoModal}
        onOk={() => setMoreInfoModal(false)}
        onCancel={() => setMoreInfoModal(false)}
        width={600}
        className="common-modal-form"
      >
        <div>
          <Text className="ant-modal-title">
            Order ID: {columnValue && columnValue.orderid}
          </Text>
          <div style={{ marginTop: "20px" }}>
            <span className="form-title">Reason for Cancellation</span>
            <p>{columnValue && columnValue.cancel_reason}</p>
            <span className="form-title">Notes for Cancellation</span>
            <p>{columnValue && columnValue.cancel_notes}</p>
          </div>
        </div>
      </Modal>
      <PaymentDetails
        visible={paymentDetails}
        data={currentColumn && currentColumn}
        onCancel={() => setPaymentDetails(false)}
        width={1000}
        title={`Payment Details - Booking ID: ${
          currentColumn && currentColumn.bid
        } (${currentColumn && currentColumn.patient_name})`}
      />
      <DetailsDrawer
        getData={() =>
          props.getSingleHomeHealthcareBooking(`id=${showDetailsDrawer}`)
        }
        visible={showDetailsDrawer}
        data={props.singleBooking}
        setVisible={() => setShowDetailsDrawer(false)}
        config={drawerConfig}
        title={`Details for Booking ID: ${get(props.singleBooking, "bid")}`}
      />
      <Row gutter={[0, 16]}>
        <Col className="gutter-row section-heading" span={12} offset={0}>
          <Title
            level={2}
            className="d-flex"
            style={{ justifyContent: "space-between", alignItems: "center" }}
          >
            All Booking
          </Title>
        </Col>
        <Col className="gutter-row section-heading" span={7} offset={0}>
          <Title
            level={4}
            className="d-flex title"
            style={{ marginTop: "10px" }}
          >
            Total: {props.bookings?.total_count}
          </Title>
        </Col>
        <Col className="gutter-row" span={3} offset={0}>
          <Button
            className={`${
              searchOption === true ? "search-open" : "search-close"
            } search-toggle-button`}
            onClick={() => toggleSearch()}
          >
            <SearchOutlined /> Search
          </Button>
        </Col>
        <Col className="gutter-row" span={2} offset={0}>
          <Button
            shape="circle"
            icon={<SyncOutlined />}
            style={{ marginRight: 8 }}
            onClick={() => handleRefresh()}
          />
        </Col>
      </Row>
      {searchOption === true ? (
        <AdvancedSearchForm
          onSearch={onSearch}
          onClose={() => setSearchOption(false)}
          title="Bookings"
          formItems={[
            {
              key: "q[bid_cont]",
              label: "Booking ID",
            },
            {
              key: "q[module_eq]",
              label: "Service name",
              Component: (
                <Select
                  showSearch
                  placeholder="Select Service"
                  size="large"
                  onChange={handleServiceSelect}
                >
                  {serviceListOption &&
                    serviceListOption?.map((val) => (
                      <Option key={val} value={val}>
                        {val}
                      </Option>
                    ))}
                </Select>
              ),
            },
            {
              key: "q[home_sessions_user_id_eq]",
              label: "Caregiver name",
              Component: (
                <Select
                  showSearch
                  filterOption={(inputValue, option) =>
                    option.props.children
                      .toString()
                      .toLowerCase()
                      .includes(inputValue.toLowerCase())
                  }
                  placeholder="Select Caregiver"
                  size="large"
                >
                  {staffListOption && selectedService
                    ? staffListOption
                        ?.filter(
                          (each) => each.caregiver_type == selectedService
                        )
                        ?.map((val) => (
                          <Option key={val.name} value={val.id}>
                            {val.name}
                          </Option>
                        ))
                    : staffListOption?.map((val) => (
                        <Option key={val.name} value={val.id}>
                          {val.name}
                        </Option>
                      ))}
                </Select>
              ),
            },
            {
              key: "q[patient_name_cont]",
              label: "Patient name",
            },
            {
              key: "q[address_cont]",
              label: "Patient location",
            },
            {
              key: "q[program_name_cont]",
              label: "Program name",
              Component: (
                <Select
                  showSearch
                  filterOption={(inputValue, option) =>
                    option.props.children
                      .toString()
                      .toLowerCase()
                      .includes(inputValue.toLowerCase())
                  }
                  placeholder="Select Program"
                  size="large"
                >
                  {programListOption && selectedService
                    ? programListOption
                        ?.filter((each) => each.service_name == selectedService)
                        ?.map((val) => (
                          <Option key={val.package_name} value={val.id}>
                            {val.package_name}
                          </Option>
                        ))
                    : programListOption?.map((val) => (
                        <Option key={val.package_name} value={val.id}>
                          {val.package_name}
                        </Option>
                      ))}
                </Select>
              ),
            },
            {
              key: "q[status_code_eq]",
              label: "Booking Status",
              Component: (
                <Select placeholder="Select Booking status" size="large">
                  <Option value="1">Booking Placed</Option>
                  <Option value="2">Booking Confirmed</Option>
                  <Option value="3">Treatment in Progress</Option>
                  <Option value="4">Booking Completed</Option>
                  <Option value="5">Booking Cancelled</Option>
                </Select>
              ),
            },
            {
              key: "q[date_from_to]",
              label: "Date",
              Component: (
                <RangePicker
                  format={["DD/MM/YYYY", "DD/MM/YYYY"]}
                  size="large"
                />
              ),
            },
          ]}
        />
      ) : (
        ""
      )}
      <Table
        className="list_table"
        dataSource={props.bookings ? props.bookings.data : null}
        pagination={{
          pageSize: 20,
          defaultCurrent: 1,
          current: currentPage,
          total: props.bookings?.total_count,
          showSizeChanger: false,
        }}
        columns={columns}
        scroll={{ x: 800 }}
        rowKey="id"
        onChange={(e) => {
          setCurrentPage(e.current);
        }}
      />
    </Spin>
  );
}

export default UserManagement;
